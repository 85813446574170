<template>
  <b-card>
    <b-row class="p-1">
      <b-col
        lg="1"
        md="2"
      >
        <div class="d-flex">
          <div class="h5 font-weight-bolder">
            Name:
          </div>
          <div class="h5 user-profile">
            Bita
          </div>
        </div></b-col>
      <b-col
        lg="1"
        md="2"
      >
        <div class="d-flex">
          <div class="h5 font-weight-bolder">
            LastName:
          </div>
          <div class="h5 user-profile">
            Meshgi
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <statistic-card-vertical
          icon="EyeIcon"
          color="info"
          statistic="Balance"
          statistic-title="50"
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
        />
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <statistic-card-vertical
          icon="EyeIcon"
          color="info"
          statistic="Equity"
          statistic-title="80"
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
        />
      </b-col>
      <b-col
        lg="3"
        sm="12"
        md="6"
      >
        <statistic-card-vertical
          icon="EyeIcon"
          color="info"
          statistic="Trade lots"
          statistic-title="100"
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
        />
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <statistic-card-vertical
          icon="EyeIcon"
          color="info"
          statistic="Total commission"
          statistic-title="800"
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    StatisticCardVertical,
  },
  data() {
    return {
      show: true,
    }
  },

  created() {
    setTimeout(() => {
      this.show = false
    }, 1000)
  },
}
</script>
<style scoped>
    .user-profile{
        margin-left: 0.5rem;
    }
</style>
