<template>
  <b-row>
    <b-col cols="12">
      <b-card>

        <b-row>
          <b-col cols="12">
            <b-table
              sticky-header="1000px"
              no-border-collapse
              responsive
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :fields="membersfields"
              :items="filteredMembers"
            />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-pagination

              v-model="currentPage"

              :per-page="perPage"
              align="fill"
              size="sm"
              class="mt-2 mb-1"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },

  data() {
    return {
      filteredMembers: [
        {
          id: 1,
          balance: 100000,
          Total_Deposit: 780000,
          Demo: 6000,
        },
        {
          id: 2,
          balance: 200000,
          Total_Deposit: 45000,
          Demo: 85000,
        },
        {
          id: 3,
          balance: 300000,
          Total_Deposit: 50000,
          Demo: 9500,
        },

      ],
      membersfields: [
        { key: 'id', label: this.$t('id'), tdClass: 'nameOfTheClass' },
        { key: 'balance', label: this.$t('balance'), tdClass: 'nameOfTheClass' },
        { key: 'Total_Deposit', label: this.$t('Total_Deposit'), tdClass: 'nameOfTheClass' },
        { key: 'Demo', label: this.$t('Demo'), tdClass: 'nameOfTheClass' },
      ],
      perPage: 10,
      currentPage: 1,
    }
  },

}
</script>
<style>
    .nameOfTheClass{
        padding: 20px !important;
    }
</style>
