<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-tabs
      v-model="tabIndex"
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
      class="row document-tab"
    >
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            UserDetails
          </span>
        </template>
        <UserDetails />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            Metatrader Accounts
          </span>
        </template>
        <MetatraderAccounts />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            Trader History
          </span>
        </template>
        <TradeHistory />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold default">
            Money Transfer History
          </span>
        </template>
        <MoneyTransferHistory />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue'
import MetatraderAccounts from '@/views/ib-pannel/members/MetatraderAccounts.vue'
import TradeHistory from '@/views/ib-pannel/members/TraderHistory.vue'
import UserDetails from '@/views/ib-pannel/members/UserDetails.vue'
import MoneyTransferHistory from '@/views/ib-pannel/members/MoneyTransferHistory.vue'
import tabsMacher from '@/mixins/tabsMacher'

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    TradeHistory,
    MetatraderAccounts,
    MoneyTransferHistory,
    UserDetails,
  },
  mixins: [tabsMacher],
  data() {
    return {
      show: true,
    }
  },

  created() {
    setTimeout(() => {
      this.show = false
    }, 1000)
  },
}
</script>
