<template>
  <b-row>
    <b-col cols="12">
      <b-card>

        <b-row>
          <b-col cols="12">
            <b-table
              sticky-header="1000px"
              no-border-collapse
              responsive
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              :fields="membersfields"
              :items="filteredMembers"
            />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-pagination

              v-model="currentPage"

              :per-page="perPage"
              align="fill"
              size="sm"
              class="mt-2 mb-1"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },

  data() {
    return {
      filteredMembers: [
        {
          symbol: 1,
          lots: 100000,
          open_price: 780000,
          close_price: 6000,
          'p/l': 6000,
        },
        {
          symbol: 2,
          lots: 200000,
          open_price: 45000,
          close_price: 85000,
          'p/l': 85000,
        },
        {
          symbol: 3,
          lots: 300000,
          open_price: 50000,
          close_price: 9500,
          'p/l': 9500,
        },

      ],
      membersfields: [
        { key: 'symbol', label: this.$t('symbol'), tdClass: 'nameOfTheClass' },
        { key: 'lots', label: this.$t('lots'), tdClass: 'nameOfTheClass' },
        { key: 'open_price', label: this.$t('open_price'), tdClass: 'nameOfTheClass' },
        { key: 'close_price', label: this.$t('close_price'), tdClass: 'nameOfTheClass' },
        { key: 'p/l', label: this.$t('p/l') },
      ],
      perPage: 10,
      currentPage: 1,
    }
  },

}
</script>
<style>
    .nameOfTheClass{
        padding: 20px !important;
    }
</style>
